import React from "react";
import styled from "styled-components";

import { Marquee } from "../Marquee";

const Indicator = styled(Marquee)`
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: yellow;
  background-color: black;
  z-index: 100;

  ${props => `
    color: ${props.foregroundColor};
    background-color: ${props.backgroundColor};
    font-size: ${props.fontSize};
  `}
`;

export const Loading = ({
  foregroundColor = "yellow",
  backgroundColor = "black",
  fontSize = "2rem"
}) => (
  <Indicator
    foregroundColor={foregroundColor}
    backgroundColor={backgroundColor}
    fontSize={fontSize}
  >
    LOADING
  </Indicator>
);

export default Loading;
