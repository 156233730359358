import React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Page from "../components/Page";
import Loading from "../components/Loading";
import { ScrollToTop } from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const QUERY = gql`
  {
    allStuffs(sortBy: meta_lastPublicationDate_DESC) {
      edges {
        node {
          _linkType
          image
          external_url {
            _linkType
            ... on _ExternalLink {
              url
            }
          }
        }
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  margin: 6em auto;
  object-fit: contain;
  max-width: 100%;

  @media (max-width: 800px) {
    width: auto;
    height: auto;
  }
`;

export default () => (
  <>
    <Helmet>
      <title>Stuff We Like | LANNINGSMITH</title>
    </Helmet>

    <Page>
      <Query query={QUERY}>
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return error.message;

          const contents = data.allStuffs.edges.map(({ node }) => node);

          return (
            <>
              <ScrollToTop />

              {contents.map(({ image }, i) => (
                <Image
                  key={i}
                  src={image._1x.url}
                  srcSet={`${image._1x.url} 1x, ${image._2x.url} 2x`}
                  width={image._1x.dimensions.width}
                  height={image._1x.dimensions.height}
                  alt={image.alt}
                />
              ))}
            </>
          );
        }}
      </Query>
    </Page>
  </>
);
