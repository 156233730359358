import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Icons from "../Icons";
import Media from "react-media";

export const Navigation = ({ page }) => {
  const location = useLocation();

  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(prevActive => !prevActive);
  };

  const handleNavigation = event => {
    // Unless the user is holding down the cmd or shift key, close the menu
    if (!event.metaKey && !event.shiftKey) {
      setActive(false);
    }
  };

  useEffect(() => {
    const handleKeydown = event => {
      if (event.key === "Escape") {
        setActive(false);
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <Bar
      page={page}
      style={{
        backgroundColor:
          location.pathname === "/" && !active ? "rgba(0,0,0,0.5)" : "black"
      }}
    >
      <Media query="(max-width: 600px)">
        {matches =>
          matches && location.pathname === "/" ? (
            <div />
          ) : (
            <Home to="/">
              <Icons
                name="Logotype"
                color="white"
                width="12rem"
                height="1.75rem"
              />
            </Home>
          )
        }
      </Media>

      <Hamburger onClick={handleClick}>
        {active ? (
          <Icons name="Close" width="30px" height="30px" color="white" />
        ) : (
          <Icons name="Hamburger" width="30px" height="30px" color="white" />
        )}
      </Hamburger>

      {active && (
        <Overlay>
          <Internal to="/about" onClick={handleNavigation}>
            about
          </Internal>

          <Internal to="/portfolio" onClick={handleNavigation}>
            portfolio
          </Internal>

          <Internal to="/stuff" onClick={handleNavigation}>
            stuff we like
          </Internal>

          <External href="https://thegoods.lanningsmith.com/" target="_blank">
            store
          </External>

          <External
            href="https://www.instagram.com/lanningsmith/"
            target="_blank"
          >
            <Icons name="Instagram" color="white" />
          </External>
        </Overlay>
      )}
    </Bar>
  );
};

export const BAR_HEIGHT = "4rem";

const Bar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 2rem;
  color: white;
  height: ${BAR_HEIGHT};
  font-weight: bold;
  user-select: none;
  flex-wrap: wrap;
  padding: 0 0 0 1rem;
`;

const link = css`
  transition: opacity 100ms;

  &:hover {
    opacity: 0.5;
  }
`;

const Home = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${link}
`;

const Hamburger = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  padding: 1rem;
`;

const Overlay = styled.div`
  position: fixed;
  top: ${BAR_HEIGHT};
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  color: white;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
`;

const navLink = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 50px;
`;

const Internal = styled(Link)`
  ${link}
  ${navLink}
`;
const External = styled.a`
  ${link}
  ${navLink}
`;
