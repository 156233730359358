import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { asImageSrc, asText } from "@prismicio/helpers";
import { AspectRatioBox } from "../components/AspectRatioBox";
import { Helmet } from "react-helmet";
import Icons from "../components/Icons";

const SHOW_QUERY = gql`
  query Show($id: String!) {
    allClients(uid: $id) {
      edges {
        node {
          _meta {
            uid
          }
          name
          description
          projects {
            project {
              ... on Project {
                _meta {
                  id
                }
                media {
                  image
                  video
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();

  const backdropEl = useRef(null);

  const back = useCallback(() => {
    history.push("/portfolio?via=Show");
  }, [history]);

  const handleMouseDown = event => {
    if (backdropEl.current === event.target) {
      back();
    }
  };

  const handleClick = () => {
    back();
  };

  useEffect(() => {
    const handleKeydown = event => {
      if (event.key === "Escape") {
        back();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [back]);

  return (
    <>
      <Modal>
        <Container ref={backdropEl} onMouseDown={handleMouseDown}>
          <Query query={SHOW_QUERY} variables={{ id }}>
            {({ data, loading, error }) => {
              if (loading) return <Loading />;
              if (error) return error.message;

              const client = data.allClients.edges[0].node;

              return (
                <>
                  <Helmet>
                    <title>{asText(client.name)} | LANNINGSMITH</title>

                    {client.description && (
                      <meta
                        name="description"
                        content={asText(client.description)}
                      />
                    )}
                  </Helmet>

                  {client.projects.map(({ project }) => {
                    if (!project) return null;

                    return (
                      <Fragment key={project._meta.id}>
                        {project.media.map(({ video, image, ...rest }) => {
                          if (video) {
                            // return <pre>{JSON.stringify(video, null, 2)}</pre>;
                            return (
                              <AspectRatioBox
                                aspectWidth={video.width}
                                aspectHeight={video.height}
                                maxWidth={1200}
                                maxHeight={1200}
                                style={{
                                  margin: "1rem auto",
                                  backgroundColor: "gray"
                                }}
                              >
                                <Frame
                                  dangerouslySetInnerHTML={{
                                    __html: video.html
                                  }}
                                />
                              </AspectRatioBox>
                            );
                          }

                          if (!image.zoomed) return null;

                          const large = {
                            src1x: asImageSrc(image, { width: 1200 }),
                            src2x: asImageSrc(image, { width: 2400 })
                          };

                          const small = {
                            src1x: asImageSrc(image, { width: 600 }),
                            src2x: asImageSrc(image, { width: 1200 })
                          };

                          return (
                            <AspectRatioBox
                              aspectWidth={image.dimensions.width}
                              aspectHeight={image.dimensions.height}
                              maxWidth={1200}
                              maxHeight={1200}
                              style={{
                                margin: "1rem auto",
                                backgroundColor: "gray"
                              }}
                            >
                              <picture>
                                <source
                                  media="(min-width: 600px)"
                                  srcSet={`${large.src1x} 1x, ${large.src2x} 2x`}
                                />

                                <source
                                  media="(max-width: 599px)"
                                  srcSet={`${small.src1x} 1x, ${small.src2x} 2x`}
                                />

                                <img
                                  src={small.src1x}
                                  srcSet={`${small.src1x} 1x, ${small.src2x} 2x`}
                                  width="100%"
                                  height="100%"
                                  alt=""
                                  loading="lazy"
                                />
                              </picture>
                            </AspectRatioBox>
                          );
                        })}
                      </Fragment>
                    );
                  })}

                  {/* Offset height of meta at bottom of scroll */}
                  <Meta
                    style={{
                      position: "relative",
                      opacity: 0,
                      pointerEvents: "none"
                    }}
                  >
                    <div>
                      <h1>{RichText.asText(client.name)}</h1>

                      {client.description && (
                        <p>{RichText.asText(client.description)}</p>
                      )}
                    </div>
                  </Meta>

                  {/* Display actual meta */}
                  <Meta>
                    <div>
                      <h1>{RichText.asText(client.name)}</h1>

                      {client.description && (
                        <p>{RichText.asText(client.description)}</p>
                      )}
                    </div>
                  </Meta>
                </>
              );
            }}
          </Query>

          <Close onClick={handleClick}>
            <Icons name="Close" width="30px" height="30px" color="white" />
          </Close>
        </Container>
      </Modal>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem;
`;

const Close = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: difference;
`;

const Meta = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  flex-shrink: 0;
  padding: 1em;
  font-size: 1.25rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  backdrop-filter: blur(10px);

  > div {
    max-width: 80ch;
    margin: 0 auto;

    > h1,
    > p {
      text-align: left;
      font-size: 1.25rem;
      margin: 0;
      line-height: 1.25;
    }

    > h1 {
      margin-bottom: 0.5em;
      font-weight: bold;
    }
  }
`;

const Frame = styled.div`
  width: 100%;
  height: 100%;
  background-color: gray;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
