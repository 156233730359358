import React from "react";
import { ScrollToTop } from "../components/ScrollToTop";
import Page from "../components/Page";
import Splash from "../components/Splash";

const Overview = () => {
  return (
    <>
      <ScrollToTop />

      <Page mt={0}>
        <Splash />
      </Page>
    </>
  );
};

export default Overview;
