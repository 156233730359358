import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  opacity: 1;
  transition: opacity 400ms
    ${props => `
    width: ${props.width || props.size};
    height: ${props.height || props.size};
  `};

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${props => props.color};
    ${props =>
      props.rotate &&
      `
    transform: rotate(${props.rotate}deg);
    `}
  }
`;

const Hamburger = (
  <svg viewBox="0 0 20 20">
    <path
      d="M3.314,4.8h13.372c0.41,0,0.743-0.333,0.743-0.743c0-0.41-0.333-0.743-0.743-0.743H3.314
								c-0.41,0-0.743,0.333-0.743,0.743C2.571,4.467,2.904,4.8,3.314,4.8z M16.686,15.2H3.314c-0.41,0-0.743,0.333-0.743,0.743
								s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,15.2,16.686,15.2z M16.686,9.257H3.314
								c-0.41,0-0.743,0.333-0.743,0.743s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,9.257,16.686,9.257z"
    />
  </svg>
);

const LeftArrow = (
  <svg viewBox="0 0 123 83">
    <path d="M44.754.069l6.912 7.421-33.863 31.477h95V30.5h10v22h-10v-8.467H17.795l33.871 31.476-6.912 7.422L.197 41.505z" />
  </svg>
);

const RightArrow = (
  <svg viewBox="0 0 123 83">
    <path d="M78.246.069L71.334 7.49l33.863 31.477H10.198V30.5h-10v22h10v-8.467h95.007L71.334 75.509l6.912 7.422 44.557-41.426z" />
  </svg>
);

const Logotype = (
  <svg viewBox="0 0 514 75">
    <g>
      <path d="M387.958.278c-4.896 0-9.504 1.917-12.973 5.396l-1.062 1.066-1.062-1.066C369.392 2.195 364.787.278 359.89.278c-10.105 0-18.324 8.22-18.324 18.322v56.121h8.578v-56.12c0-5.372 4.373-9.743 9.746-9.743 5.371 0 9.742 4.371 9.742 9.743v56.121h8.58V18.601c0-5.372 4.371-9.743 9.746-9.743 5.373 0 9.744 4.371 9.744 9.743v56.121h8.578V18.601c0-10.103-8.218-18.323-18.322-18.323zM296.007 44.651h24.728v22.272h-24.728v7.799h32.99V36.968h-24.613V8.075h24.613V.278h-32.99zM249.914 74.722h32.993v-35.78h-14.881v7.682h6.502v20.299h-16.236V8.075h16.236v13.586h8.379V.278h-32.993zM488.96 20.017V.278h-50.404v7.797h11.494v66.647h8.377V8.075h22.037v66.647h8.381V27.814h16.234v46.908h8.498V20.017zM419.397.278h8.381v74.443h-8.381zM109.092 50.169L92.461.278h-8.1v74.444h8.378V25.32l16.513 49.402h8.102V.278h-8.262zM36.992 66.923H8.801V.278H.423v74.443h44.948V38.845h16.004v35.877h8.379V.278H36.992v66.645zm8.379-58.848h16.004v22.739H45.371V8.075zM227.278 50.169L210.648.278h-8.1v74.444h8.378V25.32l16.513 49.402h8.102V.278h-8.263zM156.691 50.169L140.061.278h-8.101v74.444h8.378V25.32l16.514 49.402h8.101V.278h-8.262zM179.561.278h8.378v74.443h-8.378z" />
    </g>
  </svg>
);

const Return = (
  <svg viewBox="0 0 137 107">
    <path d="M120.119 90.572l-3.459-3.701c9.488-8.865 14.932-21.395 14.932-34.375 0-25.936-21.102-47.036-47.037-47.036H.342V.395h84.212c28.729 0 52.104 23.373 52.104 52.102 0 14.376-6.029 28.255-16.539 38.075z" />
    <path d="M113.592 60.822l-28.979 41.369 50.319 4.414z" />
  </svg>
);

const Instagram = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 49.471c67.266 0 75.233.257 101.8 1.469 24.562 1.121 37.9 5.224 46.778 8.674a78.052 78.052 0 0 1 28.966 18.845 78.052 78.052 0 0 1 18.845 28.966c3.45 8.877 7.554 22.216 8.674 46.778 1.212 26.565 1.469 34.532 1.469 101.8s-.257 75.233-1.469 101.8c-1.121 24.562-5.225 37.9-8.674 46.778a83.427 83.427 0 0 1-47.811 47.811c-8.877 3.45-22.216 7.554-46.778 8.674-26.56 1.212-34.527 1.469-101.8 1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051 78.051 0 0 1-28.966-18.845 78.053 78.053 0 0 1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s.257-75.233 1.469-101.8c1.121-24.562 5.224-37.9 8.674-46.778a78.052 78.052 0 0 1 18.847-28.967 78.053 78.053 0 0 1 28.966-18.845c8.877-3.45 22.216-7.554 46.778-8.674 26.565-1.212 34.532-1.469 101.8-1.469m0-45.391c-68.418 0-77 .29-103.866 1.516-26.815 1.224-45.127 5.482-61.151 11.71a123.488 123.488 0 0 0-44.62 29.057A123.488 123.488 0 0 0 17.3 90.982c-6.223 16.025-10.481 34.337-11.7 61.152C4.369 179 4.079 187.582 4.079 256s.29 77 1.521 103.866c1.224 26.815 5.482 45.127 11.71 61.151a123.489 123.489 0 0 0 29.057 44.62 123.486 123.486 0 0 0 44.62 29.057c16.025 6.228 34.337 10.486 61.151 11.71 26.87 1.226 35.449 1.516 103.866 1.516s77-.29 103.866-1.516c26.815-1.224 45.127-5.482 61.151-11.71a128.817 128.817 0 0 0 73.677-73.677c6.228-16.025 10.486-34.337 11.71-61.151 1.226-26.87 1.516-35.449 1.516-103.866s-.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486 123.486 0 0 0-29.057-44.62A123.487 123.487 0 0 0 421.018 17.3c-16.025-6.223-34.337-10.481-61.152-11.7C333 4.369 324.418 4.079 256 4.079Z" />
    <path d="M256 126.635A129.365 129.365 0 1 0 385.365 256 129.365 129.365 0 0 0 256 126.635Zm0 213.338A83.973 83.973 0 1 1 339.974 256 83.974 83.974 0 0 1 256 339.973Z" />
    <circle cx="390.476" cy="121.524" r="30.23" />
  </svg>
);

const Close = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
    ></path>
  </svg>
);

const Mark = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
    <path d="M37.499.278C16.976.278.278 16.976.278 37.501c0 20.523 16.697 37.221 37.221 37.221 20.525 0 37.223-16.697 37.223-37.221C74.722 16.977 58.024.278 37.499.278zM3.063 37.501v-.013h21.883c-1.689 1.31-2.912 3.001-3.574 5.028-1.723 5.275.117 12.154 8.223 14.803 1.398.457 2.812.68 4.211.68 4.418 0 8.666-2.223 11.701-6.299l-2.232-1.666c-3.26 4.377-8.17 6.152-12.814 4.637-6.754-2.205-7.689-7.459-6.441-11.289 1.291-3.949 5.066-5.385 8.045-5.894H53.54v30.396h.146A34.219 34.219 0 0 1 37.5 71.937c-18.989 0-34.437-15.448-34.437-34.436zm53.26 28.812V34.702H38.987c1.68-1.307 2.895-2.991 3.553-5.01.932-2.85.785-5.833-.412-8.401-1.387-2.973-4.088-5.186-7.811-6.4-5.822-1.901-11.918.253-15.91 5.617l2.232 1.665c3.258-4.376 8.174-6.148 12.812-4.635 2.973.97 5.1 2.674 6.152 4.931.896 1.925 1 4.184.289 6.358-1.275 3.908-4.99 5.352-7.955 5.875H3.188C4.618 17.02 19.454 3.064 37.499 3.064c18.988 0 34.436 15.447 34.436 34.437 0 12.045-6.221 22.658-15.612 28.812z" />
  </svg>
);

const ICONS = {
  Hamburger,
  LeftArrow,
  RightArrow,
  Logotype,
  Return,
  Instagram,
  Close,
  Mark
};

const Icons = ({
  name,
  size,
  width,
  height,
  color,
  rotate,
  position,
  scroll
}) => {
  return (
    <Container
      size={size}
      width={width}
      height={height}
      color={color}
      rotate={rotate}
      position={position}
      scroll={scroll}
    >
      {ICONS[name]}
    </Container>
  );
};

Icons.defaultProps = {
  color: "black",
  size: "1em",
  width: null,
  height: null
};

export default ({ name, size, width, height, color, rotate, position }) => {
  return (
    <Icons
      name={name}
      size={size}
      width={width}
      height={height}
      color={color}
      rotate={rotate}
      position={position}
    />
  );
};
