import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Overview from "./pages/Overview";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Stuff from "./pages/Stuff";
import Blank from "./pages/Blank";
import Show from "./pages/Show";

export default () => (
  <Router>
    <Route path="/" exact component={Overview} />

    <Route
      path="/portfolio"
      exact
      component={({ location, ...rest }) => {
        return (
          <Portfolio
            {...{ location, ...rest }}
            scrollToTop={!location.search.includes("via=Show")}
          />
        );
      }}
    />

    <Route
      path="/show/:id"
      component={params => {
        return (
          <>
            <Portfolio scrollToTop={false} />
            <Show {...params} />
          </>
        );
      }}
    />

    <Route path="/about" component={About} />

    <Route path="/stuff" component={Stuff} />

    <Route path="/blank" component={Blank} />
  </Router>
);
