import React, { PureComponent } from "react";
import ReactDOM from "react-dom";

export default class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el.parentNode.removeChild(this.el);
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(<>{children}</>, this.el);
  }
}
