import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

const move = props => keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-${props.offset}px);
  }
`;

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  font-size: 2rem;
  text-transform: uppercase;
  color: ${props => props.color};
  font-weight: bold;
  line-height: 1;
  ${props =>
    props.mode === "TEXT" &&
    `
    padding: 0.33em 0;
  `}
`;

const Inner = styled.div`
  animation: ${move} ${props => props.speed} infinite linear;
  cursor: default;
  user-select: none;
  white-space: nowrap;
`;

const Item = styled.div`
  display: inline-block;
`;

export const Marquee = ({ children, color = "red", ...rest }) => {
  const containerEl = useRef(null);
  const childEl = useRef(null);

  const [amount, setAmount] = useState(1);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const containerWidth = containerEl.current.getBoundingClientRect().width;
    const childWidth = childEl.current.getBoundingClientRect().width;
    const targetAmount = Math.min(Math.ceil(containerWidth / childWidth), 100);

    setOffset(targetAmount * childWidth);
    setAmount(Math.ceil(targetAmount * 2));
  }, []);

  const pool = new Array(amount).fill(children);

  // All marquees appear to move at the same speed, so we can calculate the speed
  // based on the width of the content it contains.
  const speedAmount = 50; // Lower = Slower
  const speed = `${(offset / parseFloat(speedAmount)) * 1000}ms`;

  const mode = typeof children === "string" ? "TEXT" : "IMAGE";
  const spacer = { TEXT: "\xa0\xa0", IMAGE: null }[mode];

  return (
    <Container ref={containerEl} color={color} mode={mode} {...rest}>
      <Inner speed={speed} offset={offset}>
        {pool.map((child, i) => (
          <Item key={i} ref={childEl}>
            {child}
            {spacer}
          </Item>
        ))}
      </Inner>
    </Container>
  );
};
