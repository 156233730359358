import { scale } from "proportional-scale";
import React from "react";

const responsiveScale = ({
  aspectWidth,
  aspectHeight,
  maxWidth,
  maxHeight
}) => {
  if (maxWidth === "100%") {
    return { maxWidth: "100%" };
  }

  const scaled = scale({
    width: aspectWidth,
    height: aspectHeight,
    maxWidth,
    maxHeight
  });

  return { maxWidth: `${scaled.width}px` };
};

export const AspectRatioBox = ({
  aspectWidth,
  aspectHeight,
  maxWidth,
  maxHeight,
  children,
  style,
  ...rest
}) => {
  const scaled = responsiveScale({
    aspectHeight,
    aspectWidth,
    maxWidth,
    maxHeight
  });

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        aspectRatio: `${aspectWidth} / ${aspectHeight}`,
        maxWidth: scaled.maxWidth,
        ...(style ?? {})
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

AspectRatioBox.displayName = "AspectRatioBox";
