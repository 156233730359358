import React, { useState } from "react";
import useInterval from "@use-it/interval";
import styled from "styled-components";

import { VideoCover } from "../VideoCover";

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1.01);
  transition: opacity 2000ms, transform 2000ms;

  ${props =>
    props.isActive &&
    `
    opacity: 1;
    z-index: 1;
    transform: scale(1);
  `}
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Slides = ({ slides }) => {
  const [cursor, setCursor] = useState(0);

  const activeSlide = slides[cursor % slides.length];
  // Longer transition for videos
  const transitionSpeed = activeSlide._linkType ? 20000 : 4000;

  useInterval(() => {
    setCursor(prevCursor => prevCursor + 1);
  }, transitionSpeed);

  return slides.map((slide, index) => {
    return (
      <Slide key={index} isActive={cursor % slides.length === index}>
        {slide._linkType ? (
          <VideoCover vimeoUrl={slide.url} />
        ) : (
          <Image
            src={slide["1x"].url}
            srcSet={`${slide["1x"].url} 1x, ${slide["2x"].url} 2x`}
            alt={slide.alt}
          />
        )}
      </Slide>
    );
  });
};
