import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { Query } from "react-apollo";
import Media from "react-media";
import Icons from "../Icons";
import { Slides } from "../Slides";
import { Link } from "react-router-dom";

const shuffle = xs => {
  for (let i = xs.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [xs[i], xs[j]] = [xs[j], xs[i]];
  }

  return xs;
};

const QUERY = gql`
  {
    allSplashs {
      edges {
        node {
          selected {
            image
            vimeo_url {
              _linkType
              ... on _ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Container = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  cursor: pointer;
`;

const Logo = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default () => {
  return (
    <Container to="/portfolio">
      <Logo>
        <Media query="(max-width: 800px)">
          {matches =>
            matches ? (
              <Icons
                name="Logotype"
                width="18em"
                height="20em"
                color="white"
                position="splash"
              />
            ) : (
              <Icons name="Logotype" width="30em" height="20em" color="white" />
            )
          }
        </Media>
      </Logo>

      <Query query={QUERY}>
        {({ data, loading, error }) => {
          if (loading || error) return null;

          const slides = shuffle(
            data.allSplashs.edges[0].node.selected
              .map(({ image, vimeo_url }) => [image, vimeo_url])
              .flat()
              .filter(Boolean)
          );

          return <Slides slides={slides} />;
        }}
      </Query>
    </Container>
  );
};
