import React from "react";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { PrismicLink } from "apollo-link-prismic";

import Router from "./Router";

const client = new ApolloClient({
  link: PrismicLink({
    uri: "https://lanningsmith.prismic.io/graphql"
  }),
  cache: new InMemoryCache()
});

export default () => (
  <ApolloProvider client={client}>
    <Router />
  </ApolloProvider>
);
