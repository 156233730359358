import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;

  > iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #111;
  }
`;

export const VideoCover = ({ vimeoUrl = "https://vimeo.com/290968740" }) => {
  const id = vimeoUrl.match(/\.com\/(\d+)/)[1];
  return (
    <Container>
      <iframe
        title="Video background"
        src={`https://player.vimeo.com/video/${id}?background=1&autoplay=1&loop=1&byline=0&title=0`}
        frameBorder="0"
      />
    </Container>
  );
};
