import React from "react";

import { VideoCover } from "../components/VideoCover";

export default () => {
  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <VideoCover />
      </div>
    </>
  );
};
