import React from "react";
import styled from "styled-components";
import { Navigation, BAR_HEIGHT } from "../Navigation";

const Container = styled.div`
  ${props => !!props.mt && `margin-top: ${props.mt};`}
  ${props => !!props.mb && `margin-bottom: ${props.mb};`}
`;

const Content = styled.div`
  min-height: 100vh;
`;

const Page = ({ children, mt, mb, ...rest }) => (
  <Container mt={mt} mb={mb} {...rest}>
    <Navigation />

    <Content>{children}</Content>
  </Container>
);

Page.defaultProps = {
  mt: BAR_HEIGHT
};

export default Page;
