import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { Query } from "react-apollo";
import { RichText } from "prismic-reactjs";
import Page from "../components/Page";
import Loading from "../components/Loading";
import { ScrollToTop } from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { asImageSrc } from "@prismicio/helpers";
import Media from "react-media";
import Icons from "../components/Icons";

const QUERY = gql`
  {
    allAbouts {
      edges {
        node {
          about
          clients
          design_services
          production_services
          on_and_off_site_services
          first_image
          second_image
          third_image
        }
      }
    }
  }
`;

const linkResolver = doc => {
  if (doc.type === "client") {
    return `/show/${doc.uid}`;
  }

  return null;
};

export default () => {
  return (
    <>
      <Helmet>
        <title>About | LANNINGSMITH</title>
      </Helmet>

      <Page>
        <Query query={QUERY}>
          {({ data, loading, error }) => {
            if (loading) return <Loading />;
            if (error) return error.message;

            const {
              about,
              clients,
              design_services,
              production_services,
              on_and_off_site_services,
              first_image,
              second_image,
              third_image
            } = data.allAbouts.edges[0].node;

            const firstImage = {
              src1x: asImageSrc(first_image, { width: 800 }),
              src2x: asImageSrc(first_image, { width: 1600 })
            };

            const secondImage = {
              src1x: asImageSrc(second_image, { width: 800 }),
              src2x: asImageSrc(second_image, { width: 1600 })
            };

            const thirdImage = {
              src1x: asImageSrc(third_image, { width: 800 }),
              src2x: asImageSrc(third_image, { width: 1600 })
            };

            return (
              <>
                <ScrollToTop />

                <Text
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "2rem"
                  }}
                >
                  <h3>About</h3>
                  {RichText.render(about)}
                </Text>

                <Grid>
                  <Media query="(max-width: 800px)">
                    {matches => {
                      return matches ? (
                        <>
                          <Text>
                            <h3>Design Services</h3>
                            {RichText.render(design_services)}
                          </Text>

                          <img
                            src={firstImage.src1x}
                            srcSet={`${firstImage.src1x} 1x, ${firstImage.src2x} 2x`}
                            alt={first_image.alt}
                          />

                          <Text>
                            <h3>Production Services</h3>
                            {RichText.render(production_services)}
                          </Text>

                          <img
                            src={secondImage.src1x}
                            srcSet={`${secondImage.src1x} 1x, ${secondImage.src2x} 2x`}
                            alt={first_image.alt}
                          />

                          <Text>
                            <h3>On and Off-Site Services</h3>
                            {RichText.render(on_and_off_site_services)}
                          </Text>

                          <img
                            src={thirdImage.src1x}
                            srcSet={`${thirdImage.src1x} 1x, ${thirdImage.src2x} 2x`}
                            alt={first_image.alt}
                          />
                        </>
                      ) : (
                        <>
                          <Text>
                            <h3>Design Services</h3>
                            {RichText.render(design_services)}
                          </Text>

                          <img
                            src={firstImage.src1x}
                            srcSet={`${firstImage.src1x} 1x, ${firstImage.src2x} 2x`}
                            alt={first_image.alt}
                          />

                          <img
                            src={secondImage.src1x}
                            srcSet={`${secondImage.src1x} 1x, ${secondImage.src2x} 2x`}
                            alt={first_image.alt}
                          />

                          <Text>
                            <h3>Production Services</h3>
                            {RichText.render(production_services)}
                          </Text>

                          <Text>
                            <h3>On and Off-Site Services</h3>
                            {RichText.render(on_and_off_site_services)}
                          </Text>

                          <img
                            src={thirdImage.src1x}
                            srcSet={`${thirdImage.src1x} 1x, ${thirdImage.src2x} 2x`}
                            alt={first_image.alt}
                          />
                        </>
                      );
                    }}
                  </Media>
                </Grid>

                <Text
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "2rem"
                  }}
                >
                  <h3>Clients & Collaborators</h3>
                  {RichText.render(clients, linkResolver)}
                </Text>
              </>
            );
          }}
        </Query>

        <Footer>
          <Email>
            <Icons name="Mark" color="black" width="60px" height="60px" />

            <div style={{ marginLeft: "1em" }}>
              Please direct all inquires to:
              <br />
              <a href="mailto:studio@lanningsmith.com">
                STUDIO@LANNINGSMITH.COM
              </a>
            </div>
          </Email>

          <div>
            <a
              href="https://www.google.com/maps/place/LANNINGSMITH/@40.7042686,-73.9863092,17z/data=!4m13!1m7!3m6!1s0x89c25a32508adb9f:0x8bfe7511d9aeee8c!2s25+Jay+St,+Brooklyn,+NY+11201!3b1!8m2!3d40.7042686!4d-73.9863092!3m4!1s0x0:0x3d441853fdfa08cb!8m2!3d40.7041103!4d-73.9865665"
              target="_blank"
              rel="noopener noreferrer"
            >
              LANNINGSMITH NYC
              <br />
              25 JAY ST.
              <br />
              BROOKLYN NY
            </a>
          </div>

          <div>
            <a
              href="https://goo.gl/maps/uhd7rdgTzLJfd5mM8"
              target="_blank"
              rel="noopener noreferrer"
            >
              LANNINGSMITH STUDIO
              <br />
              2321 HOMEWOOD AVE.
              <br />
              BALTIMORE MD
            </a>
          </div>
        </Footer>
      </Page>
    </>
  );
};

const Email = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 1em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;

    @media (max-width: 800px) {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-fit: cover;
  }
`;

const Text = styled.div`
  font-size: 1.5rem;
  text-align: center;
  background-color: white;
  color: black;

  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;

    @media (max-width: 800px) {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

  @media (max-width: 800px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.25;
  overflow: hidden;

  > div {
    padding: 2em;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    font-size: 1.25rem;
    line-height: 1.5;
    padding-bottom: 2em;

    > div {
      padding: 1em;
    }
  }
`;
