import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import Loading from "../components/Loading";
import { Marquee } from "../components/Marquee";
import { Helmet } from "react-helmet";
import { ScrollToTop } from "../components/ScrollToTop";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Title = styled(Marquee).attrs({
  speed: "5s"
})`
  position: absolute;
  font-size: 1.5rem;
  color: black;
  background-color: white;
`;

const Thumb = styled(Link)`
  position: relative;
  cursor: pointer;
  user-select: none;

  > img {
    display: block;
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 100%;
  }

  &:nth-child(even) {
    ${Title} {
      background-color: black;
      color: white;
    }
  }

  @media (max-width: 800px) {
    /* Alternate every two items */
    &:nth-child(2n + 1) {
      ${Title} {
        background-color: black;
        color: white;
      }
    }

    &:nth-child(2n) {
      ${Title} {
        background-color: white;
        color: black;
      }
    }

    /* Then reverse it every other row */
    &:nth-child(4n + 1) {
      ${Title} {
        background-color: white;
        color: black;
      }
    }

    &:nth-child(4n + 2) {
      ${Title} {
        background-color: black;
        color: white;
      }
    }
  }
`;

const QUERY = gql`
  {
    allClients(
      sortBy: position_ASC
      where: { display_as_fulltext: "client" }
      first: 100
    ) {
      edges {
        node {
          _meta {
            uid
          }
          name
          cover

          override_image
          override_html
        }
      }
    }
  }
`;

const Portfolio = ({ scrollToTop = true }) => {
  return (
    <>
      {scrollToTop && <ScrollToTop />}

      <Helmet>
        <title>LANNINGSMITH</title>
      </Helmet>

      <Page mt="4rem">
        <Query query={QUERY}>
          {({ data, loading, error }) => {
            if (loading) return <Loading />;
            if (error) return error.message;

            return (
              <Grid>
                {data.allClients.edges.map(({ node }) => {
                  return (
                    <Thumb key={node._meta.uid} to={`/show/${node._meta.uid}`}>
                      <Title>{node.name[0].text}</Title>
                      <img src={node.cover.url} alt="" />
                    </Thumb>
                  );
                })}
              </Grid>
            );
          }}
        </Query>
      </Page>
    </>
  );
};

export default Portfolio;
